.login {
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 50px 60px;
  border: 1px black solid;
  border-radius: 10px;
  min-width: 20rem;
  width: 30rem;
  margin: auto;
  margin-top: 20px;
  &__header {
    color: black;
    margin: auto !important;
    padding-top: 20px;
    margin-bottom: 20px !important;
    margin-top: 0 !important;
  }
  &__logo {
    margin: auto;
    width: auto;
    height: auto;
    max-width: 200px;
    max-height: 200px;
  }
  &__tabs {
    background-color: white!important;
    color: black !important;
  }
  &__alert {
    margin: 20px;
    max-width: 500px;
  }
  &__email {
    margin-bottom: 20px !important;
    margin-right: 20px !important;
  }
  &__password {
    margin-bottom: 20px !important;
  }
  &__passwordless-check {
    flex-direction: row !important;
    justify-content: center;
  }
  &__selected-tab {
    padding: 30px;
    width: 100;
    display: flex;
    justify-content: center;
  }
  &__selected-tab .MuiTextField-root {
    width: 100%;
  }
  &__phone-number {
    margin: auto;
    margin-bottom: 20px !important;
    width: 50%;
  }
  &__phone-number .MuiFormLabel-root {
    font-size: 1.1rem !important;
  }
  &__phone-number .MuiInputBase-root {
    font-size: 1.1rem !important;
  }
}